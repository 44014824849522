/* Path: vnroyal/src/router.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "inter", sans-serif;
}

nav {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  z-index: 1000;
  transition: background 0.3s, padding 0.3s;
}

.layout {
  overflow-x: hidden;
}

.logo img {
  width: 190px;
  height: 150px;
  border-radius: 0 0 16px 16px;
}

.nav-links {
  list-style: none;
  display: flex;
  flex: 1;
  justify-content: center;
}

.nav-links li {
  margin: 0 1rem;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-links li a:hover {
  color: #feb47b;
}

.language-select button {
  background: none;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.language-select button span {
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.drop-btn {
  color: whitesmoke;
  padding: 9px 32px;
  font-size: 15px;
  cursor: pointer;
  background-color: transparent;
  border-color: whitesmoke;
  border-radius: 3px;
}

body {
  font-style: "Inter", sans-serif;
}

.HP-Banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(
      90deg,
      rgba(13, 82, 144, 0.88) 1.25%,
      #072b4b 60.28%,
      rgba(4, 24, 42, 0.84) 100%
    ),
    url("./circuitImage.png");
  background-size: auto;
  background-position: center;
  color: #fff;
}

@media screen and (max-width: 500px) {
  .HP-Banner {
    background: linear-gradient(
        90deg,
        rgba(13, 82, 144, 0.88) 1.25%,
        #072b4b 60.28%,
        rgba(4, 24, 42, 0.84) 100%
      ),
      url("./circuitImage.png");
    height: 85vh;
  }
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.content {
  max-width: 800px;
  text-align: center;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hero-content {
  text-align: center;
}

.hero img {
  max-width: 100%;
  height: auto;
}
