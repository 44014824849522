body {
  margin: 0;
  padding: 0;
}

.HP-screen {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
}

.banner-image img {
  width: 568px;
  height: 500px;
  margin-top: 85px;
  object-fit: cover;
  box-shadow: -22px 22px rgba(9, 175, 100, 0.519);
}

.texts {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 645px;
  margin-top: 11%;
  margin-left: 10%;
}

.texts h1 {
  color: #24e06c;
  font-size: 92px;
  font-weight: 650;
  margin: 5px;
}

.texts h3 {
  font-size: 30px;
  color: whitesmoke;
  font-weight: 300;
  margin: 20px;
}

.BannerText {
  display: flex;
  /* width: 47vw; */
  flex-direction: column;
}

.HP-BannerText1 {
  color: #24e06c;
  font-size: 105px;
  font-weight: 600;
  /* position: absolute;
  top: 29.5%;
  left: 14.5%; */
}

.banner-image {
  /* margin-top: 90px; */
  display: flex;
  justify-content: flex-end;
}

.Hp-text-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top:20px; */
  padding-top: 20px;
}

.banner-image img {
  width: 500px;
  height: 360px;
  object-fit: cover;
  box-shadow: -22px 22px rgba(9, 175, 100, 0.519);
}

.HP-BannerText2 {
  font-size: 33px;
  color: whitesmoke;
  font-weight: 300;
  /* position: absolute;
  left: 16%;
  top: 54%; */
}

.buttonprops.MuiButton-outlined {
  background: #24e06c;
  width: 166px;
  height: 43px;
  border-radius: 5px;
  color: #000000;
  font-weight: 600;
  font-size: small;
}

.HPContactus {
  background-image: linear-gradient(
    to right,
    #0d5290,
    #0079b7,
    #00c3a2,
    #00c3a2,
    #24e06c
  );
  width: 100%;
  height: 460px;
  display: flex;
  align-items: center;

  .left {
    width: 900px;
  }
}

.HPCU-heading {
  font-size: 64px;
  font-weight: 700;
  line-height: 48px;
  color: #ffffff;
  padding-top: 60px;
  margin: 0;
}

.HPCU-sub-heading {
  color: #ffffff;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  margin-top: 20px;
  margin-bottom: 35px;
  width: 65%;
}

.right {
  display: inline;
}

.HPCU-img {
  width: 520px;
  height: 100%;
}

.HP-OurServices {
  display: flex;
  align-items: center;
}

.services-left {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.service {
  display: flex;
  flex-direction: row;
  height: 341px;
}

.service-box-left {
  width: 135px;
}

.service-box-right {
  width: 470px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  font-weight: 700;
  text-wrap: wrap;
  color: white;
}

.web-development {
  background: #1a5430b2;
}

.ui-ux-design {
  background: #2b4a66cc;
}

.digital-marketing {
  background: #625825cc;
}

.services-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 64px;
  font-weight: 700;
  text-wrap: wrap;
  padding: 49px;
}

.service-box-right.web-development {
  background-image: url(../../../public/WebDevelopment.jpg) !important;
  background-size: cover !important;
  background-position: center;
  position: relative;
}

.service-box-right.web-development::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1a5430b2;
  pointer-events: none; /* Allows clicks to pass through */
  z-index: 1;
}

.service-box-right .content {
  color: white;
  z-index: 2;
}

.service-box-right.ui-ux-design {
  background-image: url(../../../public/UiUxDesign.jpg) !important;
  background-size: cover !important;
  background-position: center;
  position: relative;
}

.service-box-right.ui-ux-design::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2b4a66cc;
  pointer-events: none; /* Allows clicks to pass through */
}

.service-box-right.digital-marketing {
  background-image: url(../../../public/DigitalMarketing.jpg) !important;
  background-size: cover !important;
  background-position: center;
  position: relative;
}

.service-box-right.digital-marketing::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #625825cc;
  pointer-events: none; /* Allows clicks to pass through */
}

.our-services-btn.MuiButton-outlined {
  background: #24e06c;
  width: 200px;
  height: 43px;
  border-radius: 5px;
  color: #000000;
  font-weight: 600;
  font-size: small;
}

.HPCU-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 60px;
  width: 100%;
}

.HPCU-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

@media screen and (max-width: 780px) {
  .banner-image img {
    opacity: 0.5;
    box-shadow: none;
    width: 100%;
  }

  .HP-screen {
    flex-direction: column-reverse;
    transform: translateY(-12%);
    top: auto;
  }

  .HP-OurServices {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .texts h1 {
    font-size: 40px;
    font-weight: 600;
    margin: 0;
  }

  .banner-image {
    margin-top: 0;
  }

  .texts h3 {
    font-size: 15px;
    font-weight: 280;
    margin: 14px;
    text-align: center;
  }

  .service-box-left {
    display: none;
  }

  .service-box-right {
    font-size: 36px;
    width: 100%;
  }

  .services-right {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    font-size: 40px;
    font-weight: 700;
    text-wrap: wrap;
    padding: 49px;
    gap: 40px;
  }

  .our-services-btn.MuiButton-outlined {
    background: #24e06c;
    width: 100%;
    height: 43px;
    border-radius: 5px;
    color: #000000;
    font-weight: 600;
    font-size: small;
    border: none;
    outline: none;
  }

  .HPCU-container {
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
  }

  .HPContactus {
    background: rgb(36, 224, 108);
    background: linear-gradient(
      231deg,
      rgba(36, 224, 108, 1) 27%,
      rgba(13, 82, 144, 1) 62%,
      rgba(13, 82, 144, 1) 100%
    );
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .left {
      width: 900px;
    }
  }

  .HPCU-img {
    width: 100%;
    height: 421px;
  }

  .HPCU-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 46px 24px;
    text-align: center;
  }

  .HPCU-heading {
    font-size: 40px;
  }

  .HPCU-sub-heading {
    font-size: 20px;
    line-height: 28px;
    width: 100%;
    word-wrap: break-word;
  }

  .texts {
    width: 280px;
    margin: 40px;
  }

  .Background {
    background: linear-gradient(to bottom, #0d5290 40%, #072b4b 75%);
    height: 575px;
    border-radius: 10px;
  }

  .icon1 {
    font-size: 13px;
    top: 33px;
  }

  .icon2 {
    font-size: 14px;
    top: 40px;
  }

  .icon3 {
    font-size: 16.5px;
    top: 47.5px;
  }

  .icon4 {
    font-size: 19px;
    top: 55px;
  }
}

@media (min-width: 780px) and (max-width: 900px) {
  .HPCU-img {
    width: 415px;
    height: 100%;
  }

  .HPCU-heading {
    font-size: 48px;
  }

  .HPCU-sub-heading {
    font-size: 24px;
    width: 600px;
  }
}
