.CU-Background {
  background: linear-gradient(
    126.37deg,
    #1d4488 10.44%,
    #0d5290 51.8%,
    #24e06c 108.4%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-top: 12%;
}

.CU-Texts {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CU-Texts h1 {
  color: #24e06c;
  font-size: 42px;
  font-weight: 700;
  margin: 10px 0;
}

.CU-Texts p {
  color: #ffffff;
  font-weight: 300;
  text-align: center;
  margin: 10px;
  padding: 10px;
}

.CU-Texts h5 {
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  line-height: 25px;
  width: 50vw;
  margin: 20px 20px 10px;
  padding-bottom: 18px;
}

.CU-body {
  display: flex;
  justify-content: space-around;
  padding-bottom: 40px;
}

.CU-form form {
  width: 500px;
  padding: 10px;
}

.CU-form label {
  display: block;
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
  margin: 10px 0 5px;
}

.CU-form button {
  display: block;
  background: #24e06c;
  padding: 13px 0 13px 13px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  border-radius: 5px;
  border: none;
}

.CU-form input {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 13px 0 13px 13px;
  width: 100%;
}

.CU-form textarea {
  border: 1px solid #ccc;
  margin-bottom: 40px;
  border-radius: 5px;
  padding: 13px 0 13px 13px;
  width: 100%;
  resize: none;
}

.CU-form input::placeholder,
.CU-form textarea::placeholder {
  color: #333333;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
}

.CU-map {
  background: #189348;
  margin: 10px;
  width: 28vw;
  border-radius: 7px;
}

.map{
  padding:20px 20px 0 20px;
}

.map iframe {
  width: 100%;
  height: 370px;
  border-radius: 8px;
}

.CU-address {
  padding: 15px 20px;
  line-height: 20px;
}

.CU-address h4 {
  color: #ffffff;
  font-size: 17px;
  font-weight: 600;
  margin: 5px 0;
}

.CU-address p {
  color: #ffffff;
  font-size: 15px;
  width: 90%;
}

.CU-Link {
  color: #ffffff;
  text-align: center;
  letter-spacing: 2px;
  width: 100%;
  padding: 3px 0;
  font-weight: 600;
}

.CU-Link a {
  color: #ffffff;
}

@media (max-width:600px){
  .CU-Texts h1{
    font-size: 35px;
    margin-bottom: 15px;
  } 

  .CU-body{
    flex-direction:column;
    padding-bottom: 0;
  }

  .CU-Texts h5{
    font-size: 20px;
    width:100%;
    margin:10px 0;
  }

  .CU-Texts p{
    margin:0;
    font-size:14px;
  }

  .CU-form label{
    font-size: 16px;
    margin:18px 0;
  }

  .CU-form form{
    width: 100vw;
    padding:25px;
  }

  .CU-map{
    width:100vw;
    padding:20px 10px;
    margin:100px 0 0 0;
    border-radius: 0;
  }

  .CU-Background {
    background: linear-gradient(
      126.37deg,
      #1d4488 10.44%,
      #0d5290 51.8%,
      #24e06c 108.4%
    );
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: 100px;
  }

}

@media (min-width:601px) and (max-width: 900px){
  .CU-body{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
  }
  
  .CU-Texts h5{
    font-size: 20px;
    width:100%;
    margin:10px 0;
  }

  .CU-map{
    width:450px;
    padding:20px 10px;
    margin:100px 0 0 0;
    border-radius: 5px;
  }

  .CU-form form{
    padding:0;
    width:450px;
  }
}

@media (min-width:901px) and (max-width:1400px){
  .CU-body{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
  }

  .CU-Texts h5{
    font-size: 20px;
    width:100%;
    margin:10px 0;
  }

  .CU-map{
    width:500px;
    padding:20px 10px;
    margin:100px 0 0 0;
    border-radius: 5px;
  }

  .CU-form form{
    padding:0;
    width:500px;
  }
}
