.language-selector {
    position: relative;
    display: flex;
    width: 240px; /* Adjust width as needed */
    justify-content: center;
    align-items: center;
  }
  
  .language-selector select {
    width: calc(100% - 70px); /* Adjust width to account for both icons */
    padding: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #000;
    border-radius: 5px;
    background: transparent;
    font-size: 16px;
    color: #000;
    padding-left: 40px; /* Ensure space for icons */
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>');
    background-size: 20px; /* Adjust icon size (increased to 16px) */
    background-repeat: no-repeat;
    background-position: right 10px center; /* Position the chevron icon */
  }

  /* Option styling */
.language-selector select option {
    color: #000; /* Set option text color to black */
    background: #fff; /* Set option background color to white */
  }
  
  .language-selector .chevron-black:focus {
    border-color: #000;
    outline: none;
  }

  .language-selector .chevron-white:focus {
    border-color: #fff;
    outline: none;
  }

  .language-selector .chevron-black {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>');
    border: 1px solid black;
    color: black;
  }
  
  .language-selector .chevron-white {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M6 9l6 6 6-6"/></svg>');
    border: 1px solid white;
    color: white;
  }
  
  .icon {
    position: absolute;
    top: 55%;
    left: 45px;
    transform: translateY(-50%);
  }
  
  .icon img {
    width: 20px; /* Adjust globe icon size as needed */
    height: auto;
    vertical-align: middle;
  }
  