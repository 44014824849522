.OV-Background {
  background: linear-gradient(
    122.08deg,
    #0d5290 0%,
    #0d528f 0.01%,
    #005a22 100%
  );
}

.OV-Screen h1 {
  color: #24e06c;
  font-size: 45px;
  padding: 15px;
  margin: 5px;
}

.OV-Screen {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.OV-Screen span {
  background-color: #ffffff66;
  flex: 1;
  height: 38px;
}

.OV-Texts {
  color: #ffffff;
  padding: 35px 0 50px 0;
}

.pointer {
  cursor: pointer;
}

.text-1,
.text-2,
.text-3,
.text-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
}

.text-1 h5,
.text-2 h5,
.text-3 h5,
.text-4 h5 {
  width: 700px;
  height: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin: 0;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-1 h2,
.text-2 h2,
.text-3 h2,
.text-4 h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  width: 285px;
  height: 100%;
}

.text-1 h2,
.text-3 h2 {
  background-color: #1a5430;
}

.text-2 h2,
.text-4 h2 {
  background-color: #189348;
}

.text-1 h5,
.text-3 h5 {
  background-color: #1a54304d;
}

.text-2 h5,
.text-4 h5 {
  background-color: #1893484d;
}

/* carousel styles start */

.sm-our-values {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 20px;
}

.sm-our-values h2 {
  height: 150px;
  background-color: #1a5430;
  display: grid;
  place-items: center;
}

.OV-Texts .sm-our-values:nth-child(even) h2 {
  background-color: #189348;
}

.OV-Texts .sm-our-values:nth-child(even) h5 {
  background-color: rgba(24, 147, 72, 0.3);
}

.sm-our-values h5 {
  height: 350px;
  background-color: rgba(26, 84, 48, 0.4);
  padding: 40px;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  text-align: center;
}

.sm-our-values-circles {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.small-carousel {
  display: none;
  place-items: center;
}

.large-screen {
  display: grid;
  place-items: center;
}

/* carousel styles end */

@media (min-width: 601px) and (max-width: 900px) {
  .OV-Screen h1 {
    font-size: 35px;
  }

  .OV-Screen span {
    height: 35px;
  }

  .text-1 h5,
  .text-2 h5,
  .text-3 h5,
  .text-4 h5 {
    width: 450px;
    font-size: 15px;
    line-height: 22px;
    padding: 0 35px 0 50px;
  }

  .text-1 h2,
  .text-2 h2,
  .text-3 h2,
  .text-4 h2 {
    font-size: 22px;
    width: 230px;
  }

  .OV-Texts {
    padding: 45px;
  }
}

@media (min-width: 901px) and (max-width: 1300px) {
  .OV-Texts {
    padding: 48px 0 50px 0;
  }

  .OV-Screen h1 {
    font-size: 37px;
  }

  .OV-Screen span {
    height: 37px;
  }

  .text-1 h5,
  .text-2 h5,
  .text-3 h5,
  .text-4 h5 {
    width: 500px;
    font-size: 16px;
    line-height: 23px;
    padding: 0 35px 0 50px;
  }

  .text-1 h2,
  .text-2 h2,
  .text-3 h2,
  .text-4 h2 {
    font-size: 24px;
    width: 240px;
  }
}

@media (max-width: 600px) {
  .small-carousel {
    display: grid;
  }

  .large-screen {
    display: none;
  }
}
