.navbar {
    width: 100%;
    background-color: transparent;
    position: fixed;
    top: 0;
    z-index: 10;
  }

.scrolled {
  background: rgba(4, 24, 42, 0.7);
}
  
  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 5vw;
    width: 100%;
  }

  .navbar-content{
    display: flex;
    gap: 8px;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    align-items: center;
  }
  
  .navbar-logo {
    display: flex;
    align-items: center;
    margin-right: auto;
  }
  
  .navbar-logo img {
    height: 120px;
    width: 170px;
    border-radius: 0 0 16px 16px;
  }
  
  .navbar-links {
    display: flex;
    list-style: none;
    gap: 28px;
  }
  
  .navbar-links li {
    margin: 0 15px;
  }
  
  .navbar-links a {
    text-decoration: none;
    color: #fff;
    font-weight: 500;
  }
  
  .language-select {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  
  .language-select button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
  }




  .mobile-navbar {
    display: none;
  }

  @media screen and (max-width: 780px) {
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1pxsolid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #1f5156;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      color: #ffdd40;
      border: none;
    }
    .nav-links {
      padding: 1.5rem;
      width: 100%;
      display: table;
    }

    #nav-icon2 span {
      display: block;
      position: absolute;
      height: 5px; /* Reduced height */
      width: 50%;
      background: rgba(13, 82, 144, 1);
      opacity: 1;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
    
    #nav-icon2 span:nth-child(even) {
      left: 50%;
      border-radius: 0 5px 5px 0; /* Adjusted border-radius */
    }
    
    #nav-icon2 span:nth-child(odd) {
      left:0px;
      border-radius: 5px 0 0 5px; /* Adjusted border-radius */
    }
    
    #nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
      top: 0px;
    }
    
    #nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
      top: 10px; /* Adjusted position */
    }
    
    #nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
      top: 20px; /* Adjusted position */
    }
    
    #nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    
    #nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    
    #nav-icon2.open span:nth-child(1) {
      left: 5px;
      top: 5px; /* Adjusted position */
    }
    
    #nav-icon2.open span:nth-child(2) {
      left: calc(50% - 5px);
      top: 5px; /* Adjusted position */
    }
    
    #nav-icon2.open span:nth-child(3) {
      left: -50%;
      opacity: 0;
    }
    
    #nav-icon2.open span:nth-child(4) {
      left: 100%;
      opacity: 0;
    }
    
    #nav-icon2.open span:nth-child(5) {
      left: 5px;
      top: 15px; /* Adjusted position */
    }
    
    #nav-icon2.open span:nth-child(6) {
      left: calc(50% - 5px);
      top: 15px; /* Adjusted position */
    }

    
   

     #nav-icon2{
      width: 40px; /* Reduced width */
      height: 30px; /* Reduced height */
      position: relative;
      margin: 8px; /* Adjusted margin */
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
    }

    /* .menu-icon {
      padding-right: 12px;
    } */
  

    .navbar-links{
      display: none;
    }


    .mobile-navbar {
      background-color: #fff;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 90px;
    }
    
    .logo img {
      height: 30px;
    }
    
    /* .menu-icon span {
      display: block;
      width: 30px;
      border: 3px solid rgba(13, 82, 144, 1);
      margin: 5px 0;
      border-radius: 3px;
    }

    .menu-icon span:last-child {
      width: 15px; 
      margin-left: auto; 
    } */
    
    .menu {
      position: absolute;
      top: 90px;
      left: 0;
      width: 100%;
      height: 70vh;
      background-color: white;
      display: none;
      flex-direction: column;
      align-items: center;
    }

    @keyframes fadeIn {
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
  }
    
    .menu.open {
      display: flex;
      animation: fadeIn 0.5s ease-in-out forwards;
      opacity: 0;
    }
    
    .menu ul {
      list-style: none;
      padding: 0;
    }
    
    .menu li {
      margin: 10px 0;
    }
    
    .menu a {
      color: #fff;
      text-decoration: none;
      font-size: 18px;
    }

    .navbar {
      display: none;
    }

    .navbar-logo img {
      height: 80px;
    }

    /* .menu-icon.active {
      font-size: 2.8rem;
      cursor: pointer;
      color: rgba(13, 82, 144, 1);
    } */

    .divider {
      border: 1px solid rgba(13, 82, 144, 1);
      display: flex;
      position: absolute;
      top: 12px;
      border-radius: 1px;
      width: 80%;
    }

    .mobile-nav-list {
      display: flex;
      list-style: none;
      flex-direction: column;
      justify-content: space-evenly;
      height: 60%;
      align-items: center;
    }
    
    .mobile-nav-list li {
      margin: 0 15px;
      font-family: Roboto;
font-size: 18px;
font-weight: 700;
line-height: 21.09px;
text-align: left;
    }
    
    .mobile-nav-list a {
      text-decoration: none;
      color: rgba(30, 30, 30, 1);
      font-weight: 500;
    }

    .language-select {
      display: flex;
      align-items: center;
      margin-left: 0;
    }
    
    .language-select button {
      background: none;
      border: none;
      outline: 1px #1f5156;
      color: rgba(30, 30, 30, 1);
      cursor: pointer;
      font-size: 16px;
    }

    #overlay.show {
      display: block;
    }

    #overlay {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
    }

    body.no-scroll {
      overflow: hidden;
    }
    
  }
  