.OS-background {
    background: linear-gradient(107.36deg, rgba(29, 68, 136, 0.8) 5.86%, rgba(13, 82, 144, 0.8) 59.57%, rgba(36, 224, 108, 0.8) 110.09%), url('./OurServicesBanner.jpg');

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
}

.OS-Text {
    display: block;
}

.OS-Text-Mobile,
.OS-Text-Tab {
    display: none;
}

.OS-Text-web {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.OS-Text-web h1 {
    font-size: 70px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    padding: 200px;
    line-height: 100px;
    width: 100%;
}

.OS-arrows {
    padding: 100px 0 42px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    line-height: 1px;
}

#OS-arrow1 {
    color: rgba(36, 224, 108, 0.4);
    font-size: 17px;
}

#OS-arrow2 {
    color: rgba(36, 224, 108, 0.6);
    font-size: 21px;
    margin: 0;
}

#OS-arrow3 {
    color: rgba(36, 224, 108, 0.8);
    font-size: 24px;
    margin: 0;
}

#OS-arrow4 {
    color: rgba(36, 224, 108, 1);
    font-size: 27px;
    margin: 0;
}

@media (max-width:630px) {
    .OS-background {
        background-position: right;
        height: 85vh;
    }

    .OS-Text-web,
    .OS-Text-Tab {
        display: none;
    }

    .OS-Text-Mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 120px 20px 130px;
        line-height: 50px;
    }

    .OS-Text-Mobile h4 {
        font-size: 32px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
    }

    .OS-Text-Mobile h2 {
        font-size: 43px;
        font-weight: 700;
        color: rgba(255, 255, 255, 1);
    }

    .OS-arrows {
        padding-top: 70px;
    }

    #OS-arrow1 {
        font-size: 12px;
    }

    #OS-arrow2 {
        font-size: 15px;
    }

    #OS-arrow3 {
        font-size: 18px;
    }

    #OS-arrow4 {
        font-size: 22px;
    }
}

@media (min-width:631px) and (max-width:900px) {
    .OS-background {
        background-position: right right;
    }

    .OS-Text-web,
    .OS-Text-Mobile {
        display: none;
    }

    .OS-Text-Tab {
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: rgba(255, 255, 255, 1);
        padding: 180px 70px 100px 60px;
        line-height: 50px;
    }

    .OS-Text-Tab h2 {
        font-size: 50px;
    }

    .OS-Text-Tab h3 {
        font-size: 35px;
    }

    #OS-arrow1 {
        font-size: 14px;
    }

    #OS-arrow2 {
        font-size: 17px;
    }

    #OS-arrow3 {
        font-size: 20px;
    }

    #OS-arrow4 {
        font-size: 24px;
    }

}

@media (min-width:901px) and (max-width:1300px) {

    .OS-Text-web,
    .OS-Text-Mobile {
        display: none;
    }

    .OS-Text-Tab {
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: rgba(255, 255, 255, 1);
        padding: 180px 70px 100px 120px;
        line-height: 60px;
    }

    .OS-Text-Tab h2 {
        font-size: 60px;
    }

    .OS-Text-Tab h3 {
        font-size: 38px;
    }

    #OS-arrow1 {
        font-size: 14px;
    }

    #OS-arrow2 {
        font-size: 17px;
    }

    #OS-arrow3 {
        font-size: 20px;
    }

    #OS-arrow4 {
        font-size: 24px;
    }

}