.icons {
    display: flex;
    flex-direction: column;
    color: #24e06c;
    align-items: center;
    position: absolute;
    bottom: 15%;
    animation: bounce 2s infinite; /* add animation */
  }
  
  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .icon1 {
    opacity: 0.5;
    font-size: 17px;
    position: absolute;
    top: 28px;
  }
  
  .icon2 {
    font-size: 19px;
    opacity: 0.7;
    position: absolute;
    top: 38px;
  }
  
  .icon3 {
    font-size: 23px;
    opacity: 0.9;
    position: absolute;
    top: 48px;
  }
  
  .icon4 {
    font-size: 25.5px;
    position: absolute;
    top: 60px;
  }

  @media screen and (max-width: 500px) {
    .icons {
        display: flex;
        flex-direction: column;
        color: #24e06c;
        align-items: center;
        position: absolute;
        bottom: 25%;
        animation: bounce 2s infinite; /* add animation */
      }
  }