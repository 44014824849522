.OL-header{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top:15px;
}

.OL-background{
    background: rgba(47, 240, 121, 0.2);
}

.OL-header h2{
    color: rgba(13, 82, 144, 1);
    font-size: 45px;
    font-weight:700;
    padding:15px;
    margin:5px;
}

.OL-header div{
    width:140px;
    height:36px;
    background: rgba(36, 224, 108, 0.4);
}

.OL-header div:nth-child(3){
    flex-grow: 1;
}

.OL-header1{
    display: flex;
    align-items: center;
}

.OL-Texts1{
    width:55vw;
    padding:20px 0 20px 100px;
}

.OL-Texts2{
    width:55vw;
    padding:20px 100px 20px 0;  
}

.OL-part1{
    display: flex;
   justify-content: space-between;
   align-items: center;
   padding-bottom: 20px;
}

.OL-part2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
}

.Texts1-text1 h3,
.Texts2-text1 h3{
    font-size: 30px;
    font-weight: 600;
    margin:15px 0;
    color: rgba(23, 22, 22, 1);
    width:100%;
}

.Texts1-text1 h5,
.Texts2-text1 h5{
    font-size: 20px;
    margin:10px 0;
    padding-bottom: 10px;
    color: rgba(51, 51, 51, 1);
    width:100%;
}

.Texts1-text2 p,
.Texts2-text2 p{
    color: rgba(51, 51, 51, 1);
    font-size: 20px;
    line-height: 30px;
    width:100%;
}

.OL-image1,
.OL-image2{
    width:28vw;
}

.OL-image1 img{
     width:100%;
     border-radius: 50% 0 0 50% ;
     object-fit:cover;
 }

.OL-image2 img{
    width:100%;
    border-radius: 0 50% 50% 0 ;
    object-fit: cover;
}

@media (max-width:600px){
    .OL-header h2{
        font-size: 34px;
        padding:15px 0;

    }

    .OL-header div{
        height: 29px;
        width:0 !important;
    }

    .OL-header div:nth-child(1){
        flex-grow: 1;
    }

    .OL-part1{
        flex-direction: column-reverse;
        padding: 20px 0 40px 0;
    }

    .OL-part2{
        flex-direction: column;
        padding: 20px 0;
    }

    .OL-Texts1,
    .OL-Texts2{
        width:100vw;
        padding:30px 20px 30px 50px;
    }

    .Texts1-text1 h3,
    .Texts2-text1 h3{
        font-size: 26px;
        padding: 15px 0 8px 0;
        margin:10px 0;
  
    }

    .Texts1-text1 h5,
    .Texts2-text1 h5{
        font-size: 19px;
        line-height: 20px; 
        font-weight: 600;
    }

    .Texts1-text2 p,
    .Texts2-text2 p{
        font-size: 19px;
        width: 100%;
    }

    .OL-image1 img,
    .OL-image2 img{
        border-radius: 0;
    }

    .OL-image1,
    .OL-image2{
        width:100vw;
    }
}

@media (min-width:601px) and (max-width:900px){
    .OL-header h2{
        font-size: 37px;
    }

    .OL-header div{
        height: 33px;
    }

    .OL-Texts1{
        width: 55vw;
        padding: 20px 0 20px 80px;
    }

    .OL-Texts2{
        width: 55vw;
        padding: 20px 80px 20px 0;
    }

    .Texts1-text1 h3,
    .Texts2-text1 h3{
        font-size:25px;
    }

    .Texts1-text1 h5, 
    .Texts2-text1 h5{
        font-size: 16px;
    }

    .Texts1-text2 p,
    .Texts2-text2 p{
        font-size: 16px;
        line-height: 28px;
    }

    .OL-image1,
    .OL-image2{
       width:33vw;
    }

}

@media (min-width:901px) and (max-width:1300px){
    .OL-header h2{
        font-size: 39px;
    }

    .OL-header div{
        height: 35px;
    }

    .OL-Texts1{
        width: 50vw;
        padding: 20px 0 20px 80px;
    }

    .OL-Texts2{
        width: 50vw;
        padding: 20px 80px 20px 0;
    }

    .Texts1-text1 h3,
    .Texts2-text1 h3{
        font-size:27px;
    }

    .Texts1-text1 h5,
    .Texts2-text1 h5,
    .Texts1-text2 p,
    .Texts2-text2 p{
        font-size: 18px;
    }

    .OL-image1,
    .OL-image2{
       width:35vw;
    }
}




