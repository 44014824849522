.optimal-process {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.optimal-proces-header {
  margin: auto;
  font-weight: 700;
  color: #203f97;
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.accordion {
  width: 70vw;
  margin: 5px auto;
}

.accordion-header {
  background-color: #24e06c;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-body {
  background-color: rgba(36, 224, 108, 0.2);
  border: 2px solid #24e06c;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.accordion-body .text-content {
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
}

.process-step {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0 10px 0;
}

.process-step-no {
  width: 20%;
  height: 100px;
  background-color: #203f97;
  font-size: 70px;
  border-radius: 0 50px 50px 0;
  text-align: center;
  color: #24e06c;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.process-step-content {
  padding: 20px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 600px) {
  .accordion {
    width: 100vw;
  }

  .process-step-no {
    width: 25%;
    height: 80px;
    font-size: 50px;
  }
}
