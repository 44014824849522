.vn-royal-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  height: 361px;
  gap: 20px;
}

.pointer {
  cursor: pointer;
}

.footer-social-media {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.footer-nav-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  font-size: 14px;
  color: #1e1e1e;
  font-weight: 700;
}

.footer-space-line {
  border-bottom: 3px solid #0d5290;
  width: 200px;
}

.footer-follow-us {
  font-size: 16px;
  color: #1e1e1e;
  font-weight: 700;
}

.footer-social-media-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.footer-copy-rights {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 400;
  color: #1e1e1e;
}

.footer-contact-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: end;
}

.get-in-touch {
  font-size: 20px;
  font-weight: 700;
  color: #1e1e1e;
  text-align: right;
}

.contact-info {
  font-size: 16px;
  font-weight: 400;
  color: #1e1e1e;
  text-align: right;
}

@media (max-width: 600px) {
  .vn-royal-footer {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .footer-contact-info {
    display: none;
  }
}
