.OP-background {
  background: rgba(36, 224, 108, 0.1);
}

.OP-Header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.OP-Header h2 {
  color: rgba(13, 82, 144, 1);
  font-size: 45px;
  padding: 15px;
  margin-top: 15px;
}

.OP-Header span {
  background: rgba(36, 224, 108, 0.4);
  height: 30px;
  flex: 1;
  margin-top: 15px;
}

.OP-logos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 60px 0;
}

.OP-logos div svg {
  width: 300px;
}

@media (max-width: 759px) {
  .OP-logos {
    flex-direction: column;
    margin: 5px;
    padding: 40px;
  }

  .OP-logos div svg {
    width: 220px;
    padding: 30px;
    margin: 20px;
  }

  .OP-Header h2 {
    font-size: 33px;
  }

  .OP-Header span {
    height: 30px;
  }
}

@media (min-width: 760px) and (max-width: 900px) {
  .OP-Header h2 {
    font-size: 40px;
  }

  .OP-Header span {
    height: 37px;
  }

  .OP-logos div svg {
    width: 200px;
    padding: 20px;
    margin: 20px;
  }

  .OP-logos {
    padding: 30px;
    margin: 20px;
  }
}

@media (min-width: 901px) and (max-width: 1300px) {
  .OP-Header h2 {
    font-size: 43px;
  }

  .OP-Header span {
    height: 39px;
  }

  .OP-logos div svg {
    width: 210px;
    padding: 20px;
    margin: 20px;
  }

  .OP-logos {
    padding: 30px;
    margin: 20px;
  }
}
