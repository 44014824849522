.MV-Background{
    background: rgba(36, 224, 108, 0.2);
 }

.MV-mission{
    display:flex;
    justify-content: space-between;
    height: 350px;
    padding-bottom:15px;
    margin-bottom: 15px;
 }

 .MV-Text{
  color:rgba(255, 255, 255, 1);
  z-index: 2;
 }

 .MV-mission h2::before,
 .MV-vision h2::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0 0 70px 70px;
  pointer-events: none; /* Allows clicks to pass through */
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
 }

 .MV-vision h2::before{
  border-radius: 70px 70px 0 0;
 }

.MV-mission h2,
.MV-vision h2{
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
    font-size:45px;
    font-weight: 700;
    flex-grow: 3;
    position: relative;
 }

.MV-mission p,
.MV-vision p{
    width:45vw;
    color: rgba(51, 51, 51, 1);
    font-size: 20px;
    line-height: 38px;
 }

.MV-mission span,
.MV-vision span{
   background: rgba(36, 224, 108, 0.6);
   height:100%;
   width:35px;
}

.MV-text1,
.MV-text2{
   display: flex;
   justify-content: space-evenly;
   flex-grow: 3;
   align-items: center;
 }

.MV-vision{
   padding-top:15px;
   margin-top: 15px;
   display: flex;
   justify-content: space-between;
   height: 350px;
 }

 .MV-vision p::first-letter,
 .MV-mission p::first-letter{
   font-size: 32px;
   font-weight: 400;
 }

 .MV-mission h2{
   background-image: url('./javier-allegue-barros-unsplash.jpg');
   background-size: cover;
   background-position: bottom;
   background-repeat: no-repeat;
   object-fit: cover;
    
 }

 .MV-vision h2{
   background-image: url('./joshua-hibbert-unsplash.jpg');
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   object-fit: cover;
 }

@media (max-width:600px){
    .MV-Background{
        background-color: #ffff;
     }

    .MV-text1 span,
    .MV-text2 span{
        display: none;
     }

     .MV-mission{
        flex-direction:column;
        height: 700px;
        padding:0;
        margin:0;
     }

     .MV-mission p,
     .MV-vision p{
       font-size:19px;
       line-height: 32px;
       width: 100%;
       padding: 0 20px 0 30px;
     }

     .MV-text1,
     .MV-text2{
       flex-grow: 1;
     }

     .MV-vision{
       flex-direction: column-reverse;
       height: 700px;
       padding:0;
       margin:0;
     }

     .MV-mission h2,
     .MV-vision h2{
      font-size: 35px;
      flex-grow: 2;
     }

   .MV-vision p::first-letter,
   .MV-mission p::first-letter{
     font-size: 25px;
   }
} 

@media (min-width:601px) and (max-width:900px){
  .MV-mission p,
  .MV-vision p{
    font-size: 18px;
    width:45vw;
    line-height: 32px;
  }

  .MV-mission h2,
  .MV-vision h2{
    font-size: 28px;
  }

  .MV-vision p::first-letter,
  .MV-mission p::first-letter{
    font-size: 23px;
  }
   
  .MV-text1 span,
    .MV-text2 span{
       width:26px;
   }

   .MV-text1,
   .MV-text2{
     flex-grow: 2;
   }
}

@media(min-width:901px) and (max-width:1300px){
  .MV-mission p,
  .MV-vision p{
    font-size: 20px;
    width:45vw;
    line-height: 34px;
  }
}

  
 
 


 