.AU-Background{
    background: url("./AboutPageBanner.jpg"), rgba(4, 24, 42, 0.7);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;  
    height: 100vh; 
    position: relative; 
    display: flex;
    justify-content: center;
}

.AU-Text-Mobile{
    display:none;
}

.AU-Text-web{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.AU-Text-web h1{
    
    color: rgba(36, 224, 108, 1);
    font-size:80px;
    font-weight: 700;
    width:100%;
    line-height:120px;
    padding:300px 140px 230px 200px;
}

.AU-Icons{
    padding:100px 0 42px;
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    line-height: 1px;
}
    
#AU-arrow1{
    color:rgba(36, 224, 108, 0.4);
    font-size: 17px;
    margin: 0;
}

#AU-arrow2{
    color:rgba(36, 224, 108, 0.6);
    font-size: 21px;
    margin: 0;
}

#AU-arrow3{
    color: rgba(36, 224, 108, 0.8);
    font-size: 24px;
    margin: 0;
}

#AU-arrow4{
    color: rgba(36, 224, 108, 1);
    font-size: 27px;
    margin: 0;
}

@media (max-width:600px){
    .AU-Background{
        background-position: right;
        height: 85vh;
     }

    .AU-Text-web{
        display: none;
    }

    .AU-Text-Mobile {
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         padding:90px 40px 130px;
         line-height: 50px;
    }

    .AU-Text-Mobile h4{
        font-size: 30px;
        font-weight: 700;
        color: rgba(36, 224, 108, 1);
    }

    .AU-Text-Mobile h2{
        font-size: 41px;
        font-weight: 700;
        color: rgba(36, 224, 108, 1);
    }

    .AU-Icons{
        padding-top: 70px;
    }

    #AU-arrow1{
        font-size: 12px;
    }

    #AU-arrow2{
        font-size: 15px;
    }
    
    #AU-arrow3{
        font-size: 18px;
    }
    
    #AU-arrow4{
         font-size: 22px;
    }
}

@media (min-width:601px) and (max-width:900px){
    .AU-Background{
        background-position: right;
    }

    .AU-Text-web h1{
        font-size: 45px;
        line-height: 60px;
        padding:180px 0 100px 60px;
    }

    #AU-arrow1{
        font-size: 14px;
    }

    #AU-arrow2{
        font-size: 17px;
    }
    
    #AU-arrow3{
        font-size: 20px;
    }
    
    #AU-arrow4{
         font-size: 24px;
    }
}

@media (min-width:901px) and (max-width:1200px){
    .AU-Background{
        background-position: right;
    }

    .AU-Text-web{
        width:80vw;
    }

    .AU-Text-web h1{
        font-size: 51px;
        line-height: 60px;
        padding:180px 0 100px 120px;
        width:100%;
    }

}




